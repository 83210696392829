import React, { useEffect, useState } from 'react'
import { pageTitle } from '../../helper'
import PageHeading from '../PageHeading'
import Div from '../Div'
import Spacing from '../Spacing'
import serverConfig from '../../helper/serverConfig'
import { Helmet } from 'react-helmet';

export default function CGVPage() {
  const [cgv, setCgv] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchData = async () => {
    try {
      const response = await fetch(`${serverConfig.serverUrl}?action=cgv&token=${serverConfig.authToken}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      });

      const data = await response.json();

      setCgv(data);

      //   console.log('Response: ', data);
      // console.log('title: ', data.title);
    } catch (error) {
      console.error('Error sending request', error);
    }
  };

  pageTitle(cgv.title);

  return (
    <>
      <Helmet>
        <meta name="description" content="Mentions légales et conditions d'utilisation de nos services. Informez-vous sur nos politiques relatives aux achats, paiements et livraisons. Vos garanties en un clic." />
      </Helmet>
      <PageHeading
        title={cgv.title}
        bgSrc='/images/service_hero_bg.jpeg'
        pageLinkText={cgv.code}
      />
      <Spacing lg='145' md='80' />
      <Div className="container">
        <Div className="container">
          <div dangerouslySetInnerHTML={{ __html: cgv.content }} />
        </Div>
      </Div>
    </>
  )
}
