import React, { useEffect, useState } from 'react'
import { pageTitle } from '../../helper'
import PageHeading from '../PageHeading'
import Div from '../Div'
import Spacing from '../Spacing'
import serverConfig from '../../helper/serverConfig'
import { Helmet } from 'react-helmet';

export default function CGUPage() {
  const [cgu, setCgu] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchData = async () => {
    try {
      const response = await fetch(`${serverConfig.serverUrl}?action=cgu&token=${serverConfig.authToken}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      });

      const data = await response.json();

      setCgu(data);

      //   console.log('Response: ', data);
      // console.log('title: ', data.title);
    } catch (error) {
      console.error('Error sending request', error);
    }
  };

  pageTitle(cgu.title);

  return (
    <>
      <Helmet>
        <meta name="description" content="Conditions d'utilisation de notre site web. Apprenez comment naviguer en toute sécurité, respecter nos droits d'auteur et plus encore. Votre expérience, notre priorité." />
      </Helmet>
      <PageHeading
        title={cgu.title}
        bgSrc='/images/service_hero_bg.jpeg'
        pageLinkText={cgu.code}
      />
      <Spacing lg='145' md='80' />
      <Div className="container">
        <Div className="container">
          <div dangerouslySetInnerHTML={{ __html: cgu.content }} />
        </Div>
      </Div>
    </>
  )
}
